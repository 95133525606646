@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css?family=Roboto');


.ashbury-container {
  width: 100%;
  overflow-x: hidden;
  font-family: 'poppins';
}

.menu_number_holder {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* justify-content: space-between; */
  flex-direction: row;
  background: rgba(248, 218, 204, 0.85);
  border-radius: 20px;
  align-items: center;
  padding: 0px 5px;
}

.noCssLink {
  color: black !important;
  text-decoration: none !important;
}

.blogdetail_ashbury-container {
  width: 100%;
  overflow-x: hidden;
  font-family: 'Roboto';
}


.Blogcard_detail{
  background-color: #F8F6FF;
  border-radius: 40px;
  margin-top: 3%;
  padding-bottom: 4%;
  width: 70%;
  margin-left: 14%;
  margin-right: 14%;
}

.sliderW40 {
  width: 40%
}



.w100 {
  width: 100%;
}

.w45 {
  width: 14%;
}

.w40 {
  width: 42%;
}

.w39 {
  width:43%;
}

.fs-3vw {
  font-size: 3vw !important;
}

.w35 {
  width: 36%;
}

.w53 {
  width: 54%;
}

.w48 {
  width: 48%;
}


.bk {
  background-color: #62caab;
}

.mb3{
  margin-bottom:0%;
}


.mb10 {
  margin-bottom: 10%;
}

.mt10 {
  margin-top: 1%;
}

.mt5 {
  margin-top: 5%;
}

.mt3 {
  margin-top: 3%;
}

.pb20 {
  padding-bottom: 5%;
}

.download_icon {
  margin-left: -10%;
  margin-right: 10px;
}

.f500 {
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 600;
}

.headerDiv {
  width: 100%;
  height: 10vh;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  position: relative;
  /* padding-top: 3%;
  justify-content: space-between; */
}

.headerDiv_menucall {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  margin: 11px 18px;
  width: 100%;
  align-items: center;
}

.anchor_web{
  display:flex;
  flex-direction:row;
  justify-content: space-evenly;
  padding-left: 15%;
  padding-right: 15%;
  margin: 3% 0%;
}

.anchor_mobile{
  display:none;
}

.bar_menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1% 7%;
  width: 100%;
}
.menu_holder_bar_col_md_4{
  display: flex;
  flex-direction: row;
  width: 40%;
  justify-content: space-between;
  align-items:center;
}
.headerDiv_menuBtn {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile_menu_number_holder{
  display: flex;
  align-items: center;
}
.hederDiv_callBtn {
  background: rgba(248, 218, 204, 0.85);
  border-radius: 20px;
  font-family: 'poppins';
  font-weight: 600;
  font-size:1vw;
  letter-spacing: 0em;
  text-transform: capitalize;
  color: #362F2D;
  border: none;
  padding: 5px 5px 5px 5px;
}
.hederDiv_callBtn_right{
  background: rgba(248, 218, 204, 0.85);
  border-radius: 20px;
  font-family: 'poppins';
  font-weight: 600;
  font-size: 1vw;
  letter-spacing: 0em;
  text-transform: capitalize;
  color: #362F2D;
  border: none;
  padding: 5px 5px 5px 5px;
}

.pl-5{
  padding-left: 5px;
}
.submit_button {
  background-color: #657FE3!important;
  border-radius: 28px!important;
  font-family: 'poppins'!important;
  font-weight: 600!important;
  font-size: 15px!important;
  text-transform: capitalize;
  color: #FFFFFF!important;
  /* border: none;
  padding: 5px 10px 5px 5px;
  margin-top: 3%;
  display: flex;
  align-items: center; */
}

.know_more_button {
  border-radius: 28px!important;
  font-family: 'poppins'!important;
  font-weight: 600!important;
  font-size: 13px!important;
  text-transform: capitalize;
  color: #FFFFFF!important;
  padding: 2% 0%;
  /* border: none;
  padding: 5px 10px 5px 5px;
  display: flex;
  align-items: center; */
}

.clGreen {
  background-color: #51BCA9!important;
}

.clTransparent {
  background: rgba(255, 255, 255, 0.2);
}

.clTransparentGreen {
  background: rgba(101, 105, 67, 0.2);
}

.clTransparentBrown {
  background: rgba(140, 124, 96, 0.2);
}

.mr20 {
  margin-right: 20px;
}

.ashburyLogo {
  /* margin-right: 8%; */
  /* margin-top: -3%; */
  /* z-index: 999; */
  /* margin-top: 0%; */
  z-index: 999;
  /* border: 1px solid; */
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  padding: 16px 0px 13px 20px;
  /* padding-left: 6%; */
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  /* margin-top:-1.5%; */
}
.logo_width{
  width:60px;
}

.download_button {
  background: rgba(248, 218, 204, 0.85);
  border-radius: 28px;
  font-family: 'poppins';
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0em;
  text-transform: capitalize;
  color: #362F2D;
  border: none;
  padding: 5px 5px 5px 22px;
  width: 50%;
  display: flex;
  align-items: center;
}

.clGreen {
  background-color: #51BCA9;
}

.clTransparent {
  background: rgba(255, 255, 255, 0.2);
}

.clTransparentGreen {
  background: rgba(101, 105, 67, 0.2);
}

.clTransparentBrown {
  background: rgba(140, 124, 96, 0.2);
}

.mr20 {
  margin-right: 20px;
}



.homeMainDiv {
  /* background-color: #F8F6FF; */
  background-color: #FFFFFF
}

.frm_cntrl_icon {
  position: absolute;
  top: 58%;
  left: 92%;
}
.w33{
  width: 33%;
}
.w70{
  width: 66%;
}
.field_holder_frm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.slider_mobile{
  display: none;
}
.slider_web{
  display: block;
}
.homeMainDiv_Text {
  text-align: center;
  color: #36302B;
  background-color: #F2EBE9;
  font-family: 'poppins';
  font-style: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.homeMainDiv_Text_Ashbury {
  font-weight: 400;
  font-size: 13px;
  line-height: 109.9%;
  letter-spacing: 0.1em;
  height: 45px;
}

.homeMainDiv_Text_BuildStandard {
  font-weight: 600;
  font-size: 32px;
  letter-spacing: -0.02em;
}

.standard {
  margin-top: -5%;
}

.homeMainDiv_Image {
  height: 320px;
  width: 60%
}

.img_slider_style {
  width: 100%;
  border-radius: 40px 0px 0 0;
  width: 100%;
  height: 320px;
}

.homeMainDiv_Image_Text {
  text-align: right;
  width: 72px;
  height: 18px;
  /* background-color: pink; */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 9px;
  padding-right: 2%;
  margin-left: 75%;
  padding-top: 5%;
}

.homeMainDiv_Image_Button {
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-top: 10%;
  justify-content: space-between;
  margin-top: -7%;
  margin-left: 5%;
  margin-right: 5%;
  padding-right: 3%;

}

.homeMainDiv_Image_Button_Quote {
  background-color: #657FE3;
  border-radius: 20px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* line-height: 95.9%; */
  color: #FFFFFF;
  border: none;
  padding: 5px 5px 5px 15px;
  margin-bottom: 5%;

}

.whatsapp_button {
  background-color: #1EB07C;
  border: none;
  height: 55px;
  width: 55px;
  border-radius: 50px;
  align-items: center;
  padding: 5px;
  box-shadow: 0px 15px 7px -5px rgba(0, 0, 0, 0.4);

}
.wa_icon_size{
  width:72%;
}
.facebook_button {
  background-color: #347bff;
  border: none;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  align-items: center;
  padding: 5px;
}

.homeMainDiv_Image_Button_Chatbtn_Image {
  padding-left: 5%;
}

.homeMainDiv_Products {
  background-color: #C9E7ED;
  border-radius: 40px 40px 0 0;
  margin-top: 0%;
  font-family: 'Poppins';
  font-style: normal;
  color: #262F1D;
  text-align: center;
  padding-top: 3%;
}

.card_holder {
  display: flex;
    width: 100%;
    flex-direction: row;
    /* justify-content: center; */
    padding: 2% 6% 3% 6%;
    flex-wrap: wrap;
    /* padding-right: 0%;
    padding-bottom: 0%; */
    justify-content: space-between;

}

.product-card_holder {
  display: flex;
  width: 100%;
  flex-direction: row;
  /* justify-content: space-between; */
  margin: 1% 1%;
  flex-wrap: wrap;
  /* background-color: #1EB07C; */
  margin-left: 5%;
  margin-right: 5%;
}
.alice-carousel__next-btn{
    width: 5%!important;
    border: 1px solid #F15864;
    background: rgba(248, 218, 204, 0.5);
    border-radius: 10px;
    /* opacity: 0.5; */
    position: absolute;
    top: 40%;
    right: 0%;
}
.alice-carousel__prev-btn{
  width: 5%!important;
  border: 1px solid #F15864;
  background: rgba(248, 218, 204, 0.5);
  border-radius: 10px;
  /* opacity: 0.5; */
  position: absolute;
  top: 40%;
  left: 0%;
}
.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item{
  color: #F15864!important;
  font-size: 2vw;
  font-weight: bold;
}
.alice-carousel__prev-btn-wrapper{
  text-align: center;
}
.card_view {
  background-color: #FFFFFF;
  /* width:30%; */
  width: 94%;
  border-radius: 25px;
  text-align: left;
  font-family: 'Poppins';
  font-style: normal;
  padding: 30px 18px;
  /* margin:8% 8% */
  margin-left: 0%;
  margin-top: 0%;
}

.homeMainDiv_Products_header {
  /* background-color: yellow; */
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;

}

.homeMainDiv_Products_headersub {
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.01em;
  /* margin-top:2%; */

}



.iko_div_text {
  margin-bottom: 0%;
  text-align: left;
  min-height: 184px;
}

.homeMainDiv_products_card_header {
  font-weight: 600;
  font-size: 1.5vw;
  color: #362F2D;
  text-transform: capitalize;
}

.homeMainDiv_products_card_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5vw;
  color: #5E5957;
  margin-top: 10%;
  text-align: justify;
}

.iko_div {
  margin-bottom: 15%;
  min-height: 142px;
}

.homeMainDiv_Image_Button_dowmloadbtn {
  background-color: rgba(241, 88, 100, 0.19);
  border-radius: 28px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #362F2D;

}


.product_cards {
  /* background-color: pink; */
  /* padding:20px 10px 20px 10px  */
  /* padding-right:5%; */
  /* padding-left:5%  */
  /* margin-top:50% */
  padding: 7%
}

.form_quote {
  background: #eff0f6;
  border-radius: 40px;
  height: auto;
  text-align: center;
  padding-top: 2%;
  margin-top:0%;
  /* padding-bottom: 15%; */
}

.form_header {
  font-weight: 600;
  font-size: 40px;
  line-height: 72px;
  letter-spacing: 0em;
  color: #362F2D;
  font-family: 'Poppins';
  font-style: normal;
}

.form_text_class {
  margin-top: 0px;
  font-size: 20px;
  line-height: 24px;
}

.form_text {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: rgba(54, 47, 45, 0.76);
  font-family: 'Roboto';
  font-style: italic;
}

.form_field_class {
  /* background-color: pink; */
  padding: 3% 8%;
  text-align: left;
}

.submit_btn {
  /* display:flex; */
  /* justify-content: 'center'; */
  align-items: center;
  /* background-color: #FFFFFF; */
  padding-left: 34%;
  background-color: #657FE3;
  border-radius: 28px;
}

.Asbury_main {
  background-color: #F2E4E0;
  border-radius: 40px;
}

.Ashbury_international {
  /* height: 1039px; */
    background-color: #FFFFFF;
    border-radius: 40px;
    border-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3% 15%;

}

.group_div {
  margin-top: 0%;
  /* background-color: pink; */
  display: block;
  justify-content: center;

}
.about_w50{
  width:50%
}
.about_w35{
  width: 28%;
}
.about_ml_35{
  margin-left: 35%;
}
.about_mb_10{
  margin-bottom: 0%;
}
.text {
  margin-top: 0%;
  text-align: center;
  margin-left: 0%;
}
.aboutus_img{
  width:70%;
  border-radius: 10%;
}

.Ashbury_international_title {
  /* background-color: pink; */
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 3vw;
  line-height: 3vw;
  letter-spacing: 0em;
  color: #262F1D;
  /* width:260px;
height:52px; */
  margin-left: 0%;
  margin-right: 0%;
  /* padding-left:10%;
padding-right:10%; */
  /* width:10% */
}

.Ashbury_international_subtitle {
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 2vw;
  line-height: 2vw;
  letter-spacing: 0em;
  color: #3B472F;
  margin-top: 3%;

}

.Ashbury_international_txt_1 {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 1.2vw;
  line-height: 1.5vw;
  color: #5E5957;
  margin-top: 3%;
  margin-left: 0%;
  margin-right: 0%
}

.Ashbury_international_txt_2 {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 1.2vw;
  line-height: 1.5vw;
  color: #5E5957;
  margin-top: 3%;
  margin-left: 0%;
  margin-right: 0%
}

.Ashbury_international_txt_footer {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #0F101C;
  margin-top: 5%;
  margin-left: 0%;
  margin-right: 0%
}

.News_div {
  /* height:985px; */
  background-color: #F2E4E0;
  text-align: center;

}



.OurBlog_blue {
  background-color: transparent;
  border-color: #F8F6FF;
  border-radius: 40px 40px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding:5% 5%;
  flex-wrap: wrap;

}


.OurBlog_white {
  background-color: red;
  border: 40px;
}

.working_div {
  background-image: url("../Assets/Images/working.png");
  /* background-color:#FFFFFF; */
  margin-top: 0%;
  border-radius: 40px;
  background-repeat: no-repeat;
  height: 230px;
  width: 30%;

}

.shareicon_div {
  display: flex;
  justify-content: flex-end;
  padding-top: 5%;
  margin-right: 5%;

}

.working_div_text {
  margin-top: 1%;
  width: 70%;
  text-align: left;
  padding-left: 5%;

}

.button_holder_knowmore_1 {
  padding-top: 0%;
  padding-bottom: 0%
}

.working_div_text1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  /* margin-left:20%;
  margin-right:20%; */

}

.working_div_text2 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #FFFFFF;
  margin-top: 3%;
}

.webview_email{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin:0%;
  padding-top: 1.5%;
  padding-left: 15%;
  padding-right: 15%;
  flex-wrap: wrap;
}

.Benifits {
  background-color: #E9EDC9;
  border-radius: 40px;
  margin-top: 0%;
  padding-top: 0%;
  text-align: left;
  height: 230px;
  width:30%
}

.Benifits_shareicon_div {
  margin-left: 85%;
  padding-top: 5%;

}


.Benifits_text_div {
  /* background-color: yellow; */
  /* margin-top: -7.3%; */
  margin-left: 5%;
  margin-right: 30%
}

.Benifits_text_1 {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #494B34;

}

.Benifits_text_2 {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #494B34;
  margin-top: 3%;
}

.Decra_metal {
  background-color: #E3D3B7;
  margin-top: 0%;
  border-radius: 40px;
  text-align: left;
  height: 230px;
  width:30%
}



.support {
  text-align: center;
  margin-top: 0%;
  /* background-color: #F8F6FF; */
  background-color: #F8F6FF;
  padding-top: 3%;
  border-radius: 0 0 40px 40px;
  padding-bottom: 1%;
}


.Support_header {
  font-weight: 600;
  font-size: 3vw;
  line-height: 4vw;
  letter-spacing: 0em;
  color: #262F1D;

}

.Support_headersub {
  margin-left: 0%;
  margin-right: 0%;
  margin-top: 1%;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0em;
  color: #3B472F;
}

.reason {
  text-align: center;
  margin-top: 0%;
  /* background-color: #F8F6FF; */
  background-color: #FFFFFF;
  padding-top: 3%;
  padding-bottom: 3%;
  border-radius: 0 0 40px 40px;
}


.reason_header {
  font-weight: 600;
  font-size: 40px;
  line-height: 30px;
  letter-spacing: 0em;
  color: #262F1D;
  margin-left: 20%;
  margin-right: 20%;
}

.reason_headersub {
  margin-top: 1%;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0em;
  color: #3B472F;
}

.reason_card_1 {
  width: 84%;
  border-radius: 25px;
  text-align: left;
  font-family: 'Poppins';
  font-style: normal;
  /* justify-content: space-between; */
  height: 267px;
  /* padding-top: 25%; */
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  text-transform: capitalize;
  color: #FFFFFF;
  margin-left: 0%;
  margin-top: 0%;

  /* margin-bottom: 20%; */

}

.bk_img_1 {
  background-image: linear-gradient(180deg, rgba(38, 53, 112, 0) 50%, #1F368D 100%), url('../Assets/Images/certified.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.bk_img_2 {
  background-image: linear-gradient(180deg, rgba(38, 53, 112, 0) 50%, #1F368D 100%), url('../Assets/Images/Best_price.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.bk_img_3 {
  background-image: linear-gradient(180deg, rgba(38, 53, 112, 0) 50%, #1F368D 100%), url('../Assets/Images/We_think.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.bk_img_4 {
  background-image: linear-gradient(180deg, rgba(38, 53, 112, 0) 50%, #1F368D 100%), url('../Assets/Images/Personalized.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.bk_img_5 {
  background-image: linear-gradient(180deg, rgba(38, 53, 112, 0) 50%, #1F368D 100%), url('../Assets/Images/Experience.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.bk_img_6 {
  background-image: linear-gradient(180deg, rgba(38, 53, 112, 0) 50%, #1F368D 100%), url('../Assets/Images/think.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.bk_img_portfolio {
  background-image: url('../Assets/Images/project_back.png');
  background-repeat: no-repeat;
}

.portfolio_image_holder {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 2%;
  display: flex;
  padding-bottom: 2%;
  justify-content: center;
  padding-left: 7%;
}

.portfolio_holder_mobile{
  display: none;
}
.portfolio_holder_web{
  display: flex;
}
.portfolio_show_holder_web{
  display: block;
}
.location_map_width{
  width: 100%;
  height: auto;
  border-radius:20px
}
.hexagon {
  display: flex;
  width: 70%;
  height: 265px;
  background-color: #424242;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  position: relative;

}



.hexagon2 {
  display: flex;
  width: 33%;
  height: 155px;
  background-color: #424242;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  position: absolute;
  left: 133px;
  top: 117px;

}

.hexagon3 {
  display: flex;
  width: 33%;
  height: 155px;
  background-color: #424242;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  position: absolute;
  left: 222px;
  top: 262px;

}

.reason_content {
  /* background-color: pink; */
  margin-top: 0%;
  padding-top: 65%;
    /* margin-bottom: 30%; */
}

.reason_content_txt {
  margin-top: 3%;
  margin-left: 10%;
  margin-right: 10%;
  /* border: 1px solid;   */


}

.Portfolio {
  background-color: #F8F7FF;
  border-radius: 40px;
  text-align: center;

}

.footer_text_1{
  margin-left: 2%;
  font-size: 0.94vw;
  margin-top: 1.5%;
}


.Portfolio_header {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 40px;
  line-height: 72px;
  letter-spacing: 0em;
  color: #362F2D;
  padding-top: 3%;
}

.Portfolio_text {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: rgba(54, 47, 45, 0.76);
  padding-left: 25%;
  padding-right: 25%;
}

/* For hexagon div */

.hexagon1 span {
  position: absolute;
  display: block;
  float: left;
  border-left: 170px solid #fff;
  border-right: 170px solid #fff;
}

.top1 {
  top: 0;
  border-top: 0px solid transparent;
  border-bottom: 75px solid transparent;
}

.bottom1 {
  bottom: 0px;
  border-bottom: 0px solid transparent;
  border-top: 75px solid transparent;
}

.hexagon1 {
  background: url("../Assets/Images/prj_01_bi.png");
  /* background-repeat: ; */
  width: 340px;
  height: 295px;
  position: relative;
  display: inline-block;
}






.clients {
  text-align: center;
  margin-top: 2%;
  padding-top: 1%;
}

.clients_header {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 3vw;
  line-height: 3vw;
  letter-spacing: 0em;
  color: #262F1D;
}

.clients_text {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 2vw;
  line-height: 2vw;
  letter-spacing: 0em;
  color: #3B472F;
}

.clients_images {
  margin-top: 3%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 3%;
  padding-left: 5%;
  padding-right: 5%;
  /* background-color: pink; */

}



.office {
  text-align: center;
  margin-top:5%;
  background-color: #FFFFFF;
}

/* .office_header {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 24px;
  line-height: 72px;
  letter-spacing: -0.02em;
  color: #362F2D;
} */

/* .office_text {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: rgba(54, 47, 45, 0.76);
  margin-left: 15%;
  margin-right: 15%;
} */

.card_list {
  justify-content: space-between;
  margin-top: 1%;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
}



/* .Office_card {
  align-items: left;
  border: 1px solid rgba(190, 189, 201, 0.73);
  border-radius: 30px;
  background-color: #FFFFFF;
  margin-top: 3%;
  text-align: left;
  padding-left: 8%;
  

} */

.Office_card_header {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #453F33;


}

.Office_card_body {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4F4635;
  margin-top: 5%;
  margin-right: 7%;
  min-height: 60px;
}


.Contact {
  text-align: center;
  /* margin-top: -10%; */
  /* background-color: #F8F6FF; */
  background-color: #F2EBE9;
  padding-top: 2%;
  border-radius: 40px;
  margin-top: 0%;
  padding-bottom: 1%;
  margin-bottom:5%;
}

.Support_headersub_ccontact {
  margin-left: 41%;
  margin-right: 41%;
  margin-top: 1%;
  font-weight: 500;
  font-size: 1.5vw;
  line-height: 2vw;
  letter-spacing: 0em;
  color: #3B472F;
}

.Office_card_body_final {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #4F4635;
  margin-top: 5%;
  margin-right: 15%;
}



.Footer_text {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-size: 1.5vw;
  line-height: 2vw;
  text-align: center;
  color: #5E5957;
  margin-left: 30%;
  margin-right: 30%;
  margin-top:2%;
}





.Email {
  background-color: #F8F6FF;
  border-radius: 40px;
  margin-top: 0%;
  /* margin-right:10%; */


}

.email_btn_space {
  margin-top: -5%;
}

.email_button {
  background-color: rgba(101, 127, 227, 0.19) !important;
  /* width:290px; */
  /* margin-top: 5%; */
}

.Navigation {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  margin-top: 5%;
  margin-left: 15%;
  margin-right: 15%;

  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #211F2A;
}


.Navigation_Gallery_blog {
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  margin-top: 5%;
  margin-left: 15%;
  margin-right: 15%;

  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #211F2A;
}


.Gallery {
  margin-right: 10%;

}

.need_help_button_holder {
  background-color: #F15864;
  padding: 7px 10px;
  border-radius: 28px;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 65%; */
  /* justify-content: space-between; */
  font-family: 'Roboto';
  font-weight: 600;
  margin-bottom: 1%;
}

.call_btn {
  background-color: #FFFFFF;
  color: #F15864;
  padding: 5px 7px;
  border-radius: 28px;
  border: none;
  font-family: 'Roboto';
  font-weight: 600;
  margin-right: 18px;
}

.copyrightDiv {
  width: 100%;
  background-color: #1F242C;
  display: flex;
  height: 50px;
  flex-direction: row;
  color: #FFFFFF;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  align-items: center;
}

/* AoutUspage css */

.italic {
  font-style: italic;
}

.aboutUsMainDiv {
  background-color: #FFFFFF;
  margin-top: 3%;
}



.aboutUs_Asbury_main {
  border-radius: 0 0 40px 40px;
}

.aboutUs_pinkdiv {
  background: #F2EBE9;
  border-radius: 40px;
}

.aboutUs_bluediv {
  background-color: #F8F7FF;
  border-radius: 40px;
}

.Ashbury_international_subtitle_2 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 16px;
  letter-spacing: 0em;
  color: #0F101C;
  margin-top: 6%;

}

.founding {
  background-color: #F2E4E0;
  border-radius: 40px;
}

.founding_title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 3vw;
  line-height: 3vw;
  text-align: center;
  letter-spacing: 0em;
  text-transform: capitalize;
  color: #523E38;
  margin-top: 0%;
  padding-top: 3%;
}

.founding_txt_1 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 2vw;
  line-height: 2vw;
  text-align: center;
  letter-spacing: 0em;
  color: #342521;
  margin-top: 1%;
}

.founding_txt_2 {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 1.5vw;
  line-height: 1.5vw;
  text-align: center;
  letter-spacing: 0em;
  color: #523E38;
  margin-top: 1%;
}

.dash {
  border: 0.5px solid #AD9B96;
  width: 70%;
  height: 0px;
  margin-left: 15%;
  margin-right: 15%;
}

.dash-15 {
  margin-top: 2%;
  margin-bottom: 2%;
}

.branch {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-size: 2vw;
  line-height: 2vw;
  text-align: center;
  letter-spacing: 0em;
  text-transform: capitalize;

  color: #262F1D;
}

.branch_sub_txt {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-size: 1.5vw;
  line-height: 1.5vw;
  /* or 150% */

  text-align: center;
  letter-spacing: 0em;
  text-transform: capitalize;

  color: #262F1D;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 1%;
  padding-bottom: 2%;
}

.ourVision {

  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-size: 40px;
  line-height: 40px;
  /* identical to box height, or 108% */

  text-align: center;
  letter-spacing: 0em;
  text-transform: capitalize;

  color: #262F1D;
  margin-top: 2%;
}

.ourVision_sub_1 {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 125% */

  text-align: center;
  letter-spacing: 0em;

  color: #3B472F;
  margin-top: 1%;

}

.ourVision_sub_2 {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  /* or 154% */

  text-align: center;
  letter-spacing: 0em;

  color: #3B472F;
  margin-top: 2%;
  margin-left: 15%;
  margin-right: 15%;
  padding-bottom: 2%;
}

.vision_card {
  background-color: #EEECF5;
  border-radius: 30px;
  margin-left: 7%;
  margin-right: 7%;
  padding-bottom: 3%;
}

.vision_card_header {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 40px;
  /* identical to box height, or 150% */

  text-align: center;
  text-transform: capitalize;

  color: #494B34;
  padding-top: 3%;
  margin-bottom:1%;
}

.vision_card_txt_1 {

  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  /* identical to box height, or 157% */

  text-align: center;

  color: #494B34;
  /* padding-bottom: 10%; */
  margin-top: 1%;
  margin-left: 12%;
  margin-right: 12%;
}

.dash-5 {
  margin-top: 3%;
  margin-bottom: 5%;
}

.dash_1 {
  border: 2px solid #8378A5;
  width: 20%;
  height: 0px;
  margin-left: 40%;
  margin-right: 40%;
  margin-top: -5.5%;
}

.copyrightDiv {
  width: 100%;
  background-color: #1F242C;
  display: flex;
  height: 50px;
  flex-direction: row;
  color: #FFFFFF;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  align-items: center;
}

/*DROPDOWN BUTTON*/
.dropdown-content {
  position: absolute;
  background: rgba(34, 31, 44, 0.92);
  border-radius: 0px 0px 30px 30px;
  width: 100%;
  min-height: 429px;
  text-align: center;
  color: #fff;
  top: 46px;
  z-index: 99;
  /* transition: all .5s ease-in-out; */
}

.menuHolder {
  margin-top: 10%;
}

.menuHolder {
  margin-top: 10%;
}

.menuLink {
  padding-top: 5%;
  font-family: 'Poppins';
  font-size: 1vw;
  font-weight: 600;
  /* border: 1px solid #fff; */
  /* padding-left: 2%; */
}
.subMenuHolder_web{
    position: absolute;
    width: 200px;
    border: 1px solid;
    padding: 13%;
    border-radius: 6px;
    background: #595959;
    z-index: 9999;
}
.submenulink_web{
  border-bottom: 1px solid;
  padding: 1%;
  color: #fff;
  cursor: pointer;
}
.alice-carousel__dots {
  /* margin: -30px 3px 5px !important; */
}

/* .menuLink:hover{
  background-color: #657FE3;
  
 
} */




/* ---------------   BlogPage ----------------------  */


.BlogMainDiv {
  background-color: #FFFFFF;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 5%;
}

.BlogMainDiv_News_title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 72px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #262F1D;
  margin-top: 0%;
}

.BlogMainDiv_News_subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #3B472F;
  padding-Left: 40%;
  padding-right: 40%;
}

.Blogcard {
  background-color: #F8F6FF;
  border-radius: 40px;
  margin-top: 3%;
  padding-bottom: 4%;
  width: 30%;
  margin-left: 1%;
}


.Blog_image {
  width: 100%;
}

/* .Blog_image{
  border-radius: 40px;

} */

.Blog_image_property {
  border-radius: 40px;
  width: 100%;
  height: 300px;

}


.Blogtextdiv {
  margin-top: 7%;
  padding-left: 5%;
  padding-right: 5%;
  /* background-color: #1EB07C; */
}

.Blogcardtext_1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: #262F1D;
  margin-top: 5%;
  min-height: 50px;
}

.Blogcardtext_2 {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-transform: capitalize;
  color: #7286D3;
  margin-top: 3%;
}


.Blogcardtext_3 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #5E5957;
  margin-top: 3%;
  /* padding-left:5%; */
  /* padding-right: 5%; */
  min-height: 150px;
}


.Blogcardtext_4 {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-transform: capitalize;
  color: #70747E;
}



.mt_6 {
  margin-top: 6%;
}

.blog_share_icon {
  position: absolute;
  left: 88%;
  top: 10px;
}

/*------------------  Blog Details Page--------------------- */

.italic_class {
  font-style: italic;
}

.fw {
  font-weight: 400 !important;
}

/* .ff{
    font-family: 'Roboto' !important;
   } */

.List_class {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #5E5957;
}

.Blogcardtext_title {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: #262F1D;
}


.blog_detail-form_quote {
  background: #FFFFFF;
  border-radius: 40px;
  height: 727px;
  /* margin-top: -10%; */
  text-align: center;
}


.blogdetails-form_header {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;


  text-align: center;

  color: rgba(54, 47, 45, 0.76)
}

.Blog-detail-social {
  display: flex;
  flex-direction: row;
  margin-top: 3%;
}


.ml_40 {
  margin-left: 40%;
}

/* gallary page styles */

.gallary_pinkdiv {
  background-color: #F2EBE9;
  text-align: center;
  /* padding-top: 5%; */
  margin-top: 10%;
  border-radius: 0 0 40px 40px;
}

.gallary_whitediv {
  background-color: #FFFFFF;
  border-radius: 0 0 40px 40px;
  padding-bottom: 2%;
}


.m_r_l {
  margin-left: 10% !important;
  margin-right: 10% !important;
}
.gallery_card_holder{
    display: flex;
    flex-direction: row;
    justify-content: normal;
    flex-wrap: wrap;
    padding: 4%;
}
.gallery_card_holder_4{
  display: flex;
    flex-direction: row;
    justify-content: normal;
    flex-wrap: wrap;
    padding: 4% 2% 4% 7%;
}
.gallary_card {
  background: #F8F6FF;
  border-radius: 40px;
  margin-left: 1%;
  margin-right: 0%;
  margin-top: 0%;
  /* margin-bottom: 5%; */
  /* padding-top: 5%; */
  padding-bottom: 0%;
  margin-bottom: 1%;
  width: 32%;
}

.gallary_card_4 {
  background: #F8F6FF;
  border-radius: 40px;
  margin-left: 1%;
  margin-right: 0%;
  margin-top: 0%;
  /* margin-bottom: 5%; */
  /* padding-top: 5%; */
  padding-bottom: 0%;
  margin-bottom: 1%;
  width: 18%;
}

.gallary_card_image {
  border-radius: 40px;
}

.gallary_image {
  border-radius: 40px;
  width: 100%;
  height: 300px
}

.gallary_card_text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */

  text-transform: capitalize;

  color: #262F1D;
  /* padding-top: 4%; */
  /* padding-bottom: 4%; */
  margin-top: 7%;
  margin-bottom: 5%;
  text-align: left;
  margin-left: 10%;

}


/* contact us styles */

.contact_pb {
  padding-bottom: 5% !important;
}

.mt_neg {
  margin-top: -5% !important;
}


/* ------------------------   Product Page  -------------------------------- */


.product-text-div {
  text-align: center;
  margin-top: 13%;
  margin-left: 15%;
  margin-right: 15%;
  margin-bottom: 20%;
}

.product-text {
  font-family: 'Roboto';
  /* font-weight: 400; */
  font-size: 13px;
  line-height: 18px;
  color: #5E5957;
}

.fw400 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.product_text_style {
  font-style: italic;
  /* font-weight: bold; */
}

.product-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #262F1D;
}

.text-color {
  color: #212034
}



.product-subtitle {
  font-family: 'Roboto';
  font-style: italic;
  /* font-weight: 500; */
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #5E5957;
}

.product-reason_content_txt {
  margin-top: 45%;
  margin-left: 10%;
  margin-right: 10%;
}


.product-blue {
  border-radius: 0 0 40px 40px;
  padding-bottom: 10%;
  background-color: #F8F6FF;
}


.product-white {
  border-radius: 0 0 40px 40px;
  background-color: #FFFFFF;

}

.footerLink {
  color: #000 !important;
  text-decoration: none !important;
  font-size: 16px;
  font-weight: 600;
}

.subMenuHolder {
  width: 50%;
  margin-left: 45%;
  /* margin-left: 25%; */
  text-align: left;
}

.submenulink {
  font-size: 14px;
  padding-top: 5%;
}

.footer_email {
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 28px;
  padding: 0%;
  background-color: rgba(101, 127, 227, 0.19);
  border: none;
  font-size: 0.94vw;
  width: 309px;
}

.whBtn {
  position: fixed;
  top: 50%;
  z-index: 2;
  right: 1%;
}

.office_header {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 2vw;
  line-height: 4vw;
  letter-spacing: 0em;
  color: #362F2D;
  margin-left: 5%;
  text-align: left;
}

.office_text {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 1.5vw;
  line-height: 3vw;
  color: rgba(54, 47, 45, 0.76);
  text-align: left;
  margin-left: 5%;
  margin-right: 0%;
}

/* .card_list {
  margin-top: 1%;
  padding-left: 5%;
  padding-right: 6%;
} */

.ml {
  margin-left: 3%
}

.Office_card {
  align-items: left;
  border: 1px solid rgba(190, 189, 201, 0.73);
  border-radius: 30px;
  background-color: #FFFFFF;
  margin-top: 3%;
  text-align: left;
  padding-left: 2%;
  width: 30%;
  padding-bottom: 1%;
  height:auto;
}

/* .Office_card_body {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #4F4635;
  margin-top: 1%;
  margin-right: 0%;
  padding-right: 10%;
} */

.Office_card_body_final {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #4F4635;
  margin-top: 5%;
  margin-right: 15%;
  padding-right: 18%;
}

.footer_fb{
  margin-top: 1.5%;
  margin-right: 6%;
}

.blog_view{
  display:flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: normal;
  margin-top:1%;
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 5%;
}

@media screen and (max-width: 745px)and (min-width: 450px) {

  .headerDiv_menucall {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 11px 18px;
    width: 100%;
    align-items: center;
  }

  .bar_menu {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    margin: 11px 18px;
    width: 100%;
  }
  .w70{
    width:100%;
  }
  .w33{
    width:100%;
  }
  .field_holder_frm {
    display: block;
  }

}

@media screen and (max-width: 376px)and (min-width: 280px) {


  .Blogcard_detail{
    background-color: #F8F6FF;
    border-radius: 40px;
    margin-top: 3%;
    padding-bottom: 4%;
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }


  .anchor_web{
    display:none;
  }

  .anchor_mobile{
    display:block;
  }

  .w39{
    width:44%;
  }

  .blog_view{
    display:block;
    flex-direction: column;
    margin-top:0%;
    padding-left: 0%;
    padding-right: 0%;
    padding-bottom: 0%;

  }
  
  .footer_text_1{
    margin-left: 0%;
    font-size: 2.94vw;
    margin-top: 1.5%;
  }

  .webview_email {
    display: block;
    flex-direction: column;
    justify-content: space-around;
    margin:10%;
    padding-top: 0%;
    padding-left: 0%;
    padding-right: 0%;
}


  .whBtn {
    position: fixed;
    top: 50%;
    z-index: 2;
    right: 1%;
  }

  .ashbury-container {
    width: 100%;
    overflow-x: hidden;
    font-family: 'poppins';
  }

  .menu_number_holder {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    /* justify-content: space-between; */
    flex-direction: row;
    background: rgba(248, 218, 204, 0.85);
    border-radius: 20px;
    align-items: center;
    padding: 0px 5px;
  }

  .noCssLink {
    color: #fff !important;
    text-decoration: none !important;
  }

  .blogdetail_ashbury-container {
    width: 100%;
    overflow-x: hidden;
    font-family: 'Roboto';
  }

  .sliderW40 {
    width: 100%
  }

  .w100 {
    width: 100%;
  }

  .w45 {
    width: 49%;
  }

  .w40 {
    width: 42%;
  }

  .fs-3vw {
    font-size: 3vw !important;
  }

  .w35 {
    width: 48%;
  }

  .w53 {
    width: 54%;
  }

  .w48 {
    width: 48%;
  }


  .bk {
    background-color: #62caab;
  }


  .mb10 {
    margin-bottom: 10%;
  }

  .mt10 {
    margin-top: 10%;
  }

  .mt5 {
    margin-top: 5%;
  }

  .mt3 {
    margin-top: 10%;
  }

  .pb20 {
    padding-bottom: 20%;
  }

  .download_icon {
    margin-left: -10%;
    margin-right: 10px;
  }

  .f500 {
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 600;
  }

  .headerDiv {
    width: 100%;
    height: 50px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    position: relative;
    /* padding-top: 3%;
  justify-content: space-between; */
  }

  .headerDiv_menucall {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 11px 18px;
    width: 100%;
    align-items: center;
  }

  .bar_menu {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    margin: 11px 18px;
    width: 100%;
  }

  .headerDiv_menuBtn {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobile_menu_number_holder{
    display: flex;
    align-items: center;
    width: 45%;
  }

  .hederDiv_callBtn {
    /* width: 100%;
  height: 32px; */
    background: rgba(248, 218, 204, 0.85);
    border-radius: 20px;
    font-family: 'poppins';
    font-weight: 600;
    font-size: 2.3vw;
    letter-spacing: 0em;
    text-transform: capitalize;
    color: #362F2D;
    border: none;
    padding: 5px 5px 5px 5px;
  }
  .mobile_right_button_holder{
    display: flex;
    width: 40%;
    justify-content: center;
  }
  .hederDiv_callBtn_right{
    background: rgba(248, 218, 204, 0.85);
    border-radius: 20px;
    font-family: 'poppins';
    font-weight: 600;
    font-size: 2.3vw;
    letter-spacing: 0em;
    text-transform: capitalize;
    color: #362F2D;
    border: none;
    padding: 5px 5px 5px 5px;
  }
  .download_button {
    background: rgba(248, 218, 204, 0.85);
    border-radius: 28px;
    font-family: 'poppins';
    font-weight: 600;
    font-size: 13px;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: #362F2D;
    border: none;
    padding: 5px 10px 5px 17px;
    display: flex;
    align-items: center;
  }

  .submit_button {
    background-color: #657FE3;
    border-radius: 28px;
    font-family: 'poppins';
    font-weight: 600;
    font-size: 3.2vw;
    text-transform: capitalize;
    color: #FFFFFF;
    border: none;
    padding: 5px 10px 5px 5px;
    margin-top: 3%;
    display: flex;
    align-items: center;
  }

  .know_more_button {
    border-radius: 28px;
    font-family: 'poppins';
    font-weight: 600;
    font-size: 3vw;
    text-transform: capitalize;
    color: #FFFFFF;
    border: none;
    padding: 5px 10px 5px 5px;
    display: flex;
    align-items: center;
    
  }

  .clGreen {
    background-color: #51BCA9;
  }

  .clTransparent {
    background: rgba(255, 255, 255, 0.2);
  }

  .clTransparentGreen {
    background: rgba(101, 105, 67, 0.2);
  }

  .clTransparentBrown {
    background: rgba(140, 124, 96, 0.2);
  }

  .mr20 {
    margin-right: 20px;
  }
  .mobile_logo_holder{
    z-index: 999;
    margin-top: 16%;
  }
  .ashburyLogo {
    
    z-index: 999;
    /* border: 1px solid; */
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 50%;
    /* padding: 0 0 0 6%; */
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
    /* margin-top:2%; */
  }
  .logo_width{
    width:60px;
  }
  /* .download_button {
    background: rgba(248, 218, 204, 0.85);
    border-radius: 28px;
    font-family: 'poppins';
    font-weight: 600;
    font-size: 13px;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: #362F2D;
    border: none;
    padding: 4% 4% 4% 10%;
  } */

  .clGreen {
    background-color: #51BCA9;
  }

  .clTransparent {
    background: rgba(255, 255, 255, 0.2);
  }

  .clTransparentGreen {
    background: rgba(101, 105, 67, 0.2);
  }

  .clTransparentBrown {
    background: rgba(140, 124, 96, 0.2);
  }

  .mr20 {
    margin-right: 20px;
  }



  .homeMainDiv {
    /* background-color: #F8F6FF; */
    background-color: #FFFFFF
  }

  .frm_cntrl_icon {
    position: absolute;
    top: 58%;
    left: 92%;
  }
  .w33{
    width: 100%;
  }
  .w70{
    width: 100%;
  }
  .field_holder_frm {
    display: block;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .slider_mobile{
    display: block;
  }
  .slider_web{
    display: none;
  }
  .homeMainDiv_Text {
    text-align: center;
    color: #36302B;
    background-color: #F2EBE9;
    font-family: 'poppins';
    font-style: normal;
    padding-top: 13%;
    padding-bottom: 15%;
    display: block;
  }

  .homeMainDiv_Text_Ashbury {
    font-weight: 400;
    font-size: 13px;
    line-height: 109.9%;
    letter-spacing: 0.1em;
    height: 45px;
  }

  .homeMainDiv_Text_BuildStandard {
    font-weight: 600;
    font-size: 32px;
    letter-spacing: -0.02em;
  }

  .standard {
    margin-top: -5%;
  }

  .homeMainDiv_Image {
    /* background-image: url('../Assets/Images/bgimage.png'); */
    /* align-items: center; */
    height: 320px;
    background-repeat: no-repeat;
    border-radius: 40px 40px 0 0;
    margin-top: 11%;
    width: 100%;
    /* display: flex; */
  }

  .img_slider_style {
    border-radius: 40px 40px 0 0;
    width: 100%
  }

  .homeMainDiv_Image_Text {
    text-align: right;
    width: 72px;
    height: 18px;
    /* background-color: pink; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 9px;
    padding-right: 2%;
    margin-left: 75%;
    padding-top: 5%;
  }

  .homeMainDiv_Image_Button {
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-top: 10%;
    justify-content: space-between;
    margin: -17% 0% 15% 30%;
    padding-right: 3%;


  }

  .homeMainDiv_Image_Button_Quote {
    background-color: #657FE3;
    border-radius: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    border: none;
    padding: 5px 5px 5px 15px;
    margin-bottom:0%;
  
  }

  .whatsapp_button {
    background-color: #1EB07C;
    border: none;
    height: 55px;
    width: 55px;
    border-radius: 50px;
    align-items: center;
    padding: 5px;
    box-shadow: 0px 15px 7px -5px rgba(0, 0, 0, 0.4);

  }
  .wa_icon_size{
    width:72%;
  }
  .facebook_button {
    background-color: #347bff;
    border: none;
    height: 40px;
    width: 40px;
    border-radius: 40px;
    align-items: center;
    padding: 5px;
  }

  .homeMainDiv_Image_Button_Chatbtn_Image {
    padding-left: 5%;
  }

  .homeMainDiv_Products {
    background-color: #C9E7ED;
    /* height: auto; */
    border-radius: 40px 40px 0 0;
    margin-top: 0%;
    font-family: 'Poppins';
    font-style: normal;
    color: #262F1D;
    text-align: center;
  }

  .card_holder {
    display: flex;
    width: 100%;
    flex-direction: row;
    /* justify-content: center; */
    margin: 0% 0%;
    flex-wrap: wrap;
    /* padding-right: 5%; */
    padding-bottom: 10%;

  }

  .product-card_holder {
    display: flex;
    width: 100%;
    flex-direction: row;
    /* justify-content: space-between; */
    margin: 1% 1%;
    flex-wrap: wrap;
    /* background-color: #1EB07C; */
    margin-left: 5%;
    margin-right: 5%;
  }
  .alice-carousel__next-btn{
    width: 15%!important;
    border: 1px solid #F15864;
    background: rgba(248, 218, 204, 0.5);
    border-radius: 10px;
    /* opacity: 0.5; */
    position: absolute;
    top: 40%;
    right: 0%;
}
.alice-carousel__prev-btn{
  width: 15%!important;
  border: 1px solid #F15864;
  background: rgba(248, 218, 204, 0.5);
  border-radius: 10px;
  /* opacity: 0.5; */
  position: absolute;
  top: 40%;
  left: 0%;
}
.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item{
  color: #F15864!important;
  font-size: 24px;
  font-weight: bold;
}
.alice-carousel__prev-btn-wrapper{
  text-align: center;
}
  .card_view {
    background-color: #FFFFFF;
    /* width:30%; */
    width: 94%;
    border-radius: 25px;
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    padding: 10% 7%;
    /* margin:8% 8% */
    margin-left: 0%;
    margin-top: 3%;
  }

  .homeMainDiv_Products_header {
    /* background-color: yellow; */
    font-weight: 600;
    font-size: 24px;
    line-height: 72px;
    letter-spacing: -0.02em;
    margin-bottom: -5%;
  }

  .homeMainDiv_Products_headersub {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.01em;
    /* margin-top:2%; */

  }



  .iko_div_text {
    margin-bottom: 0%;
    text-align: left;
    min-height: 150px;
  }

  .homeMainDiv_products_card_header {
    font-weight: 600;
    font-size: 13px;
    line-height: 118.4%;
    color: #362F2D;
    text-transform: capitalize;
  }

  .homeMainDiv_products_card_text {
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: #5E5957;
    margin-top: 10%
  }

  .iko_div {
    margin-bottom: 15%;
    min-height: 70px;
  }

  .homeMainDiv_Image_Button_dowmloadbtn {
    background-color: rgba(241, 88, 100, 0.19);
    border-radius: 28px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #362F2D;

  }


  .product_cards {
    /* background-color: pink; */
    /* padding:20px 10px 20px 10px  */
    /* padding-right:5%; */
    /* padding-left:5%  */
    /* margin-top:50% */
    padding: 7%
  }

  .form_quote {
    background: #eff0f6;
    border-radius: 40px;
    height: 727px;
    /* margin-top: -10%; */
    text-align: center;
    padding-top: 5%;

    /* padding-bottom: 15%; */
  }

  .form_header {
    font-weight: 600;
    font-size: 24px;
    line-height: 72px;
    letter-spacing: -0.02em;
    color: #362F2D;
    font-family: 'Poppins';
    font-style: normal;
  }

  .form_text_class {
    margin-top: -15px;
    font-size: 15px;
    line-height: 24px;
  }

  .form_text {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: rgba(54, 47, 45, 0.76);
    font-family: 'Roboto';
    font-style: italic;
  }

  .form_field_class {
    /* background-color: pink; */
    padding: 8%;
    text-align: left;
  }

  .submit_btn {
    /* display:flex; */
    /* justify-content: 'center'; */
    align-items: center;
    /* background-color: #FFFFFF; */
    padding-left: 34%;
    background-color: #657FE3;
    border-radius: 28px;
  }

  .Asbury_main {
    background-color: #F2E4E0;
    border-radius: 40px;
  }

  .Ashbury_international {
    /* height: 1039px; */
    background-color: #FFFFFF;
    border-radius: 40px;
    border-color: #FFFFFF;
    display: block;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0% 0%;

  }

  .group_div {
    margin-top: 10%;
    /* background-color: pink; */
    display: flex;
    justify-content: center;

  }
  .about_w50{
    width:100%
  }
  .about_w35{
    width: 36%;
  }
  .about_ml_35{
    margin-left: 35%;
  }
  .about_mb_10{
    margin-bottom: 10%;
  }
  .text {
    margin-top: 8%;
    text-align: center;
    margin-left: 0%;
  }
  .aboutus_img{
    
  }
  .Ashbury_international_title {
    /* background-color: pink; */
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #262F1D;
    /* width:260px;
height:52px; */
    margin-left: 20%;
    margin-right: 20%;
    /* padding-left:10%;
padding-right:10%; */
    /* width:10% */
  }

  .Ashbury_international_subtitle {
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #3B472F;
    margin-top: 3%;

  }

  .Ashbury_international_txt_1 {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #5E5957;
    margin-top: 7%;
    margin-left: 10%;
    margin-right: 10%
  }

  .Ashbury_international_txt_2 {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #5E5957;
    margin-top: 5%;
    margin-left: 10%;
    margin-right: 10%
  }

  .Ashbury_international_txt_footer {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #0F101C;
    margin-top: 5%;
    margin-left: 10%;
    margin-right: 10%
  }

  .News_div {
    /* height:985px; */
    background-color: #F2E4E0;
    text-align: center;

  }



  .OurBlog_blue {
    background-color: #F8F6FF;
    border-color: #F8F6FF;
    border-radius: 40px 40px 0 0;
    padding: 0% 0%;
    display: block;
  }


  .OurBlog_white {
    background-color: red;
    border: 40px;
  }

  .working_div {
    background-image: url("../Assets/Images/working.png");
    /* background-color:#FFFFFF; */
    margin-top: 10%;
    border-radius: 40px 40px 0 0;
    background-repeat: no-repeat;
    height: 290px;
    width:100%
  }

  .shareicon_div {
    display: flex;
    justify-content: flex-end;
    padding-top: 5%;
    margin-right: 5%;

  }

  .working_div_text {
    margin-top: 7%;
    width: 70%;
    text-align: left;
    padding-left: 5%;

  }

  .button_holder_knowmore_1 {
    padding-top: 10%;
    padding-bottom: 20%
  }

  .working_div_text1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    /* margin-left:20%;
  margin-right:20%; */

  }

  .working_div_text2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: 3%;
  }

  .Benifits {
    background-color: #E9EDC9;
    border-radius: 40px;
    margin-top: -10%;
    padding-top: 2%;
    text-align: left;
    height: 280px;
    width:100%
  }

  .Benifits_shareicon_div {
    margin-left: 85%;
    padding-top: 5%;

  }


  .Benifits_text_div {
    /* background-color: yellow; */
    /* margin-top: -7.3%; */
    margin-left: 5%;
    margin-right: 30%
  }

  .Benifits_text_1 {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #494B34;

  }

  .Benifits_text_2 {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #494B34;
    margin-top: 3%;
  }

  .Decra_metal {
    background-color: #E3D3B7;
    margin-top: -12%;
    border-radius: 40px;
    text-align: left;
    height: 220px;
    width: 100%;
  }



  .support {
    text-align: center;
    margin-top: -7%;
    /* background-color: #F8F6FF; */
    background-color: #F8F6FF;
    padding-top: 10%;
    border-radius: 0 0 40px 40px;
    padding-bottom: 5%;
  }


  .Support_header {
    font-weight: 600;
    font-size: 28px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #262F1D;
    /* margin-top:5% */
  }

  .Support_headersub {
    margin-left: 30%;
    margin-right: 30%;
    margin-top: 2%;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #3B472F;
  }

  .reason {
    text-align: center;
    margin-top: -7%;
    /* background-color: #F8F6FF; */
    background-color: #FFFFFF;
    padding-top: 10%;
    padding-bottom: 0%;
    border-radius: 0 0 40px 40px;
  }


  .reason_header {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #262F1D;
    margin-left: 20%;
    margin-right: 20%;
  }

  .reason_headersub {
    margin-top: 2%;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #3B472F;
  }

  .reason_card_1 {
    width: 94%;
    border-radius: 25px;
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    /* justify-content: space-between; */
    height: 230px;
    padding-top: 25%;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-left: 0%;
    margin-top: 3%;

    /* margin-bottom: 20%; */

  }

  .bk_img_1 {
    background-image: linear-gradient(180deg, rgba(38, 53, 112, 0) 50%, #1F368D 100%), url('../Assets/Images/certified.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bk_img_2 {
    background-image: linear-gradient(180deg, rgba(38, 53, 112, 0) 50%, #1F368D 100%), url('../Assets/Images/Best_price.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bk_img_3 {
    background-image: linear-gradient(180deg, rgba(38, 53, 112, 0) 50%, #1F368D 100%), url('../Assets/Images/We_think.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bk_img_4 {
    background-image: linear-gradient(180deg, rgba(38, 53, 112, 0) 50%, #1F368D 100%), url('../Assets/Images/Personalized.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bk_img_5 {
    background-image: linear-gradient(180deg, rgba(38, 53, 112, 0) 50%, #1F368D 100%), url('../Assets/Images/Experience.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bk_img_6 {
    background-image: linear-gradient(180deg, rgba(38, 53, 112, 0) 50%, #1F368D 100%), url('../Assets/Images/think.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bk_img_portfolio {
    background-image: url('../Assets/Images/project_back.png');
    background-repeat: no-repeat;
  }

  .portfolio_image_holder {
    width: 100%;
    height: 550px;
    position: relative;
    margin-top: 20%;
    padding-bottom: 0%;
    padding-left: 7%;
  }
  .portfolio_holder_mobile{
    display: block;
  }
  .portfolio_holder_web{
    display: none;
  }
  .portfolio_show_holder_web{
    display: none;
  }
  .location_map_width{
    width:100%;
    border-radius:20px
  }
  .hexagon {
    display: flex;
    width: 33%;
    height: 155px;
    background-color: #424242;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    position: absolute;
    left: 42px;
    top: -27px;

  }

  .hexagon2 {
    display: flex;
    width: 33%;
    height: 155px;
    background-color: #424242;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    position: absolute;
    left: 133px;
    top: 117px;

  }

  .hexagon3 {
    display: flex;
    width: 33%;
    height: 155px;
    background-color: #424242;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    position: absolute;
    left: 222px;
    top: 262px;

  }

  .reason_content {
    /* background-color: pink; */
    margin-top: 15%;
    padding-top: 0%;
      /* margin-bottom: 30%; */
  }

  .reason_content_txt {
    margin-top: 3%;
    margin-left: 5%;
    margin-right: 5%;
    /* border: 1px solid;   */


  }

  .Portfolio {
    background-color: #F8F7FF;
    border-radius: 40px;
    text-align: center;

  }

  .Portfolio_header {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 24px;
    line-height: 72px;
    letter-spacing: -0.02em;
    color: #362F2D;
  }

  .Portfolio_text {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: rgba(54, 47, 45, 0.76);
    padding-left: 17%;
    padding-right: 17%;
  }

  /* For hexagon div */

  .hexagon1 span {
    position: absolute;
    display: block;
    float: left;
    border-left: 170px solid #fff;
    border-right: 170px solid #fff;
  }

  .top1 {
    top: 0;
    border-top: 0px solid transparent;
    border-bottom: 75px solid transparent;
  }

  .bottom1 {
    bottom: 0px;
    border-bottom: 0px solid transparent;
    border-top: 75px solid transparent;
  }

  .hexagon1 {
    background: url("../Assets/Images/prj_01_bi.png");
    /* background-repeat: ; */
    width: 340px;
    height: 295px;
    position: relative;
    display: inline-block;
  }






  .clients {
    text-align: center;
    margin-top: 10%;
    padding-top: 0%;
  }

  .clients_header {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #262F1D;
  }

  .clients_text {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #3B472F;
  }

  .clients_images {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 7%;
    padding-left: 5%;
    padding-right: 0%;
    /* background-color: pink; */

  }



  .office {
    text-align: center;
    margin-top:0%;
    background-color: #FFFFFF;
  }

  .office_header {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 24px;
    line-height: 72px;
    color: #362F2D;
    text-align: center;
  }

  .office_text {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: rgba(54, 47, 45, 0.76);
    margin-left: 15%;
    margin-right: 15%;
    text-align: center;
  }

  .card_list {
    margin-top: 8%;
    padding-left: 5%;
    padding-right: 5%;
    display: block;
  }



  .Office_card {
    align-items: left;
    border: 1px solid rgba(190, 189, 201, 0.73);
    border-radius: 30px;
    background-color: #FFFFFF;
    margin-top: 3%;
    text-align: left;
    padding-left: 8%;
    width: 100%;
    padding-bottom: 0%;
    height: auto;
    /* margin-left:5%; */

  }

  .Office_card_header {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 2vw;
    line-height: 2vw;
    color: #453F33;


  }

  .Office_card_body {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 1vw;
    line-height: 1.5vw;
    color: #4F4635;
    margin-top: 1%;
    margin-right: 0%;
  }


  .Contact {
    text-align: center;
    /* margin-top: -10%; */
    /* background-color: #F8F6FF; */
    background-color: #F2EBE9;
    padding-top: 1%;
    border-radius: 40px;
    margin-top: 10%;
    padding-bottom: 0%;
    /* padding-bottom: %; */
  }

  .Support_headersub_ccontact {
    margin-left: 22%;
    margin-right: 22%;
    margin-top: 2%;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #3B472F;
  }

  .Office_card_body_final {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #4F4635;
    margin-top: 5%;
    margin-right: 15%;
  }



  .Footer_text {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    color: #5E5957;
    margin-left: 9%;
    margin-right: 9%;
    margin-top:4%;
  }





  .Email {
    background-color: #F8F6FF;
    border-radius: 40px;
    margin-top: 15%;
    /* margin-right:10%; */


  }

  .email_btn_space {
    margin-top: -5%;
  }

  .email_button {
    background-color: rgba(101, 127, 227, 0.19) !important;
    /* width:290px; */
    /* margin-top: 5%; */
  }

  .Navigation {
    display: flex;
    /* flex-direction: row; */
    justify-content: space-between;
    margin-top: 5%;
    margin-left: 15%;
    margin-right: 15%;

    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #211F2A;
  }


  .Navigation_Gallery_blog {
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    margin-top: 5%;
    margin-left: 15%;
    margin-right: 15%;

    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #211F2A;
  }


  .Gallery {
    margin-right: 10%;

  }

  .need_help_button_holder {
    background-color: #F15864;
    padding: 7px 10px;
    border-radius: 28px;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* width: 65%; */
    /* justify-content: space-between; */
    font-family: 'Roboto';
    font-weight: 600;
    margin-bottom: 0%;
  }

  .call_btn {
    background-color: #FFFFFF;
    color: #F15864;
    padding: 5px 7px;
    border-radius: 28px;
    border: none;
    font-family: 'Roboto';
    font-weight: 600;
    margin-right: 18px;
  }

  .copyrightDiv {
    width: 100%;
    background-color: #1F242C;
    display: flex;
    height: 50px;
    flex-direction: row;
    color: #FFFFFF;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    align-items: center;
  }

  /* AoutUspage css */

  .italic {
    font-style: italic;
  }

  .aboutUsMainDiv {
    background-color: #FFFFFF;
    margin-top: 15%;
  }



  .aboutUs_Asbury_main {
    border-radius: 0 0 40px 40px;
  }

  .aboutUs_pinkdiv {
    background: #F2EBE9;
    border-radius: 40px;
  }

  .aboutUs_bluediv {
    background-color: #F8F7FF;
    border-radius: 40px;
  }

  .Ashbury_international_subtitle_2 {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #0F101C;
    margin-top: 12%;

  }

  .founding {
    background-color: #F2E4E0;
    border-radius: 40px;
  }

  .founding_title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: #523E38;
    margin-top: 15%;
    padding-top: 15%;
  }

  .founding_txt_1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #342521;
    margin-top: 8%;
  }

  .founding_txt_2 {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #523E38;
    margin-top: 2%;
  }

  .dash {
    border: 0.5px solid #AD9B96;
    width: 70%;
    height: 0px;
    margin-left: 15%;
    margin-right: 15%;
  }

  .dash-15 {
    margin-top: 15%;
    margin-bottom: 15%;
  }

  .branch {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: capitalize;

    color: #262F1D;
  }

  .branch_sub_txt {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    text-align: center;
    letter-spacing: -0.02em;
    text-transform: capitalize;

    color: #262F1D;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 3%;
    padding-bottom: 10%;
  }

  .ourVision {

    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    /* identical to box height, or 108% */

    text-align: center;
    letter-spacing: -0.02em;
    text-transform: capitalize;

    color: #262F1D;
    margin-top: 12%;
  }

  .ourVision_sub_1 {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    text-align: center;
    letter-spacing: -0.02em;

    color: #3B472F;
    margin-top: 3%;

  }

  .ourVision_sub_2 {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    /* or 154% */

    text-align: center;
    letter-spacing: -0.02em;

    color: #3B472F;
    margin-top: 8%;
    margin-left: 15%;
    margin-right: 15%;
    padding-bottom: 18%;
  }

  .vision_card {
    background-color: #EEECF5;
    border-radius: 30px;
    margin-left: 7%;
    margin-right: 7%;
    padding-bottom: 10%;
  }

  .vision_card_header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height, or 150% */

    text-align: center;
    text-transform: capitalize;

    color: #494B34;
    padding-top: 10%;
    margin-bottom: 8%;
  }

  .vision_card_txt_1 {

    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    text-align: center;

    color: #494B34;
    /* padding-bottom: 10%; */
    margin-top: 4%;
    margin-left: 12%;
    margin-right: 12%;
  }

  .dash-5 {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .dash_1 {
    border: 2px solid #8378A5;
    width: 20%;
    height: 0px;
    margin-left: 40%;
    margin-right: 40%;
    margin-top: -5.5%;
  }

  .copyrightDiv {
    width: 100%;
    background-color: #1F242C;
    display: flex;
    height: 50px;
    flex-direction: row;
    color: #FFFFFF;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    align-items: center;
  }

  /*DROPDOWN BUTTON*/
  .dropdown-content {
    position: absolute;
    background: rgba(34, 31, 44, 0.92);
    border-radius: 0px 0px 30px 30px;
    width: 100%;
    min-height: 429px;
    text-align: center;
    color: #fff;
    top: 46px;
    z-index: 99;
    /* transition: all .5s ease-in-out; */
  }

  .menuHolder {
    margin-top: 10%;
  }

  .menuHolder {
    margin-top: 10%;
  }

  .menuLink {
    padding-top: 5%;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
    /* border: 1px solid #fff; */
    padding-left: 2%;
  }

  .alice-carousel__dots {
    /* margin: -30px 3px 5px !important; */
  }

  /* .menuLink:hover{
  background-color: #657FE3;
  
 
} */




  /* ---------------   BlogPage ----------------------  */


  .BlogMainDiv {
    background-color: #FFFFFF;
    padding-left: 5%;
    padding-right: 5%;
  }

  .BlogMainDiv_News_title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 72px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #262F1D;
    margin-top: 10%;
  }

  .BlogMainDiv_News_subtitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #3B472F;
    padding-Left: 20%;
    padding-right: 20%;
  }

  .Blogcard {
    background-color: #F8F6FF;
    border-radius: 40px;
    margin-top: 8%;
    padding-bottom: 10%;
    width: 100%;
    margin-left: 0%;
  }

  .Blog_image {
    width: 100%;
  }

  /* .Blog_image{
  border-radius: 40px;

} */

  .Blog_image_property {
    border-radius: 40px;
    width: 100%;
    height: 300px;

  }


  .Blogtextdiv {
    margin-top: 7%;
    padding-left: 5%;
    padding-right: 5%;
    /* background-color: #1EB07C; */
  }

  .Blogcardtext_1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    color: #262F1D;
    margin-top: 5%;
  }

  .Blogcardtext_2 {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-transform: capitalize;
    color: #7286D3;
    margin-top: 3%;
  }


  .Blogcardtext_3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #5E5957;
    margin-top: 3%;
    /* padding-left:5%; */
    /* padding-right: 5%; */
  }


  .Blogcardtext_4 {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-transform: capitalize;
    color: #70747E;
  }



  .mt_6 {
    margin-top: 6%;
  }

  .blog_share_icon {
    position: absolute;
    left: 88%;
    top: 10px;
  }

  /*------------------  Blog Details Page--------------------- */

  .italic_class {
    font-style: italic;
  }

  .fw {
    font-weight: 400 !important;
  }

  /* .ff{
    font-family: 'Roboto' !important;
   } */

  .List_class {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #5E5957;
  }

  .Blogcardtext_title {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    color: #262F1D;
  }


  .blog_detail-form_quote {
    background: #FFFFFF;
    border-radius: 40px;
    height: 727px;
    /* margin-top: -10%; */
    text-align: center;
  }


  .blogdetails-form_header {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;


    text-align: center;

    color: rgba(54, 47, 45, 0.76)
  }

  .Blog-detail-social {
    display: flex;
    flex-direction: row;
    margin-top: 3%;
  }


  .ml_40 {
    margin-left: 40%;
  }

  /* gallary page styles */

  .gallary_pinkdiv {
    background-color: #F2EBE9;
    text-align: center;
    /* padding-top: 5%; */
    margin-top: 15%;
    border-radius: 0 0 40px 40px;
  }

  .gallary_whitediv {
    background-color: #FFFFFF;
    border-radius: 0 0 40px 40px;
    padding-bottom: 20%;
  }


  .m_r_l {
    margin-left: 10% !important;
    margin-right: 10% !important;
  }

  .gallery_card_holder{
    display: flex;
    flex-direction: column;
    justify-content: normal;
    flex-wrap: wrap;
    padding: 4%;
}

  .gallary_card {
    background: #F8F6FF;
    border-radius: 40px;
    width: auto;
    margin-left: 4%;
    margin-right: 4%;
    margin-top: 5%;
    /* margin-bottom: 5%; */
    /* padding-top: 5%; */
    padding-bottom: 5%;
    margin-bottom: 0%;
   
  }

  .gallary_card_image {
    border-radius: 40px;
  }

  .gallary_image {
    border-radius: 40px;
    width: 100%;
    height: 300px
  }

  .gallary_card_text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height, or 100% */

    text-transform: capitalize;

    color: #262F1D;
    /* padding-top: 4%; */
    /* padding-bottom: 4%; */
    margin-top: 7%;
    margin-bottom: 5%;
    text-align: left;
    margin-left: 10%;

  }


  /* contact us styles */

  .contact_pb {
    padding-bottom: 5% !important;
  }

  .mt_neg {
    margin-top: -5% !important;
  }


  /* ------------------------   Product Page  -------------------------------- */


  .product-text-div {
    text-align: center;
    margin-top: 13%;
    margin-left: 15%;
    margin-right: 15%;
    margin-bottom: 20%;
  }

  .product-text {
    font-family: 'Roboto';
    /* font-weight: 400; */
    font-size: 13px;
    line-height: 18px;
    color: #5E5957;
  }

  .fw400 {
    font-weight: 400;
  }

  .fw500 {
    font-weight: 500;
  }

  .product_text_style {
    font-style: italic;
    /* font-weight: bold; */
  }

  .product-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #262F1D;
  }

  .text-color {
    color: #212034
  }



  .product-subtitle {
    font-family: 'Roboto';
    font-style: italic;
    /* font-weight: 500; */
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #5E5957;
  }

  .product-reason_content_txt {
    margin-top: 45%;
    margin-left: 10%;
    margin-right: 10%;
  }


  .product-blue {
    border-radius: 0 0 40px 40px;
    padding-bottom: 10%;
    background-color: #F8F6FF;
  }


  .product-white {
    border-radius: 0 0 40px 40px;
    background-color: #FFFFFF;

  }

  .footerLink {
    color: #000 !important;
    text-decoration: none !important;
  }

  .subMenuHolder {
    width: 50%;
    margin-left: 45%;
    /* margin-left: 25%; */
    text-align: left;
  }

  .submenulink {
    font-size: 14px;
    padding-top: 5%;
  }

  .footer_email {
    display: flex;
    align-items: center;
    flex-direction: row;
    border-radius: 28px;
    padding: 2%;
    background-color: rgba(101, 127, 227, 0.19);
    border: none;
    font-size: 4vw;
    width:auto;
  }

}

@media screen and (max-width: 436px)and (min-width: 377px) {

  .Blogcard_detail{
    background-color: #F8F6FF;
    border-radius: 40px;
    margin-top: 3%;
    padding-bottom: 4%;
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }

  .footer_text_1{
    margin-left: 0%;
    font-size: 2.94vw;
    margin-top: 1.5%;
  }

  .anchor_web{
    display:none;
  }

  .anchor_mobile{
    display:block;
  }
  .webview_email {
    display: block;
    flex-direction: column;
    margin:10%;
    padding-top: 0%;
    padding-left: 0%;
    padding-right: 0%;
    /* justify-content: space-around; */
}

  .whBtn {
    position: fixed;
    top: 50%;
    z-index: 2;
    right: 1%;
  }

  .ashbury-container {
    width: 100%;
    overflow-x: hidden;
    font-family: 'poppins';
  }

  .menu_number_holder {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    /* justify-content: space-between; */
    flex-direction: row;
    background: rgba(248, 218, 204, 0.85);
    border-radius: 20px;
    align-items: center;
    padding: 0px 5px;
  }

  .noCssLink {
    color: #fff !important;
    text-decoration: none !important;
  }

  .blogdetail_ashbury-container {
    width: 100%;
    overflow-x: hidden;
    font-family: 'Roboto';
  }

  .sliderW40 {
    width: 100%
  }

  .w100 {
    width: 100%;
  }

  .w45 {
    width: 49%;
  }

  .w40 {
    width: 42%;
  }

  .w39 {
    width:47%;
  }
  .blog_view{
    display:block;
    flex-direction: column;
    margin-top:0%;
    padding-left: 0%;
    padding-right: 0%;
    padding-bottom: 0%;

  }
  .fs-3vw {
    font-size: 3vw !important;
  }

  .w35 {
    width: 48%;
  }

  .w53 {
    width: 55%;
  }

  .w48 {
    width: 48%;
  }


  .bk {
    background-color: #62caab;
  }


  .mb10 {
    margin-bottom: 10%;
  }

  .mt10 {
    margin-top: 3%;
  }

  .mt5 {
    margin-top: 5%;
  }

  .mt3 {
    margin-top: 10%;
  }

  .pb20 {
    padding-bottom: 20%;
  }

  .download_icon {
    margin-left: -10%;
    margin-right: 10px;
  }

  .f500 {
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 600;
  }

  .headerDiv {
    width: 100%;
    height: 50px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    position: relative;
    /* padding-top: 3%;
  justify-content: space-between; */
  }

  .headerDiv_menucall {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 11px 18px;
    width: 100%;
    align-items: center;
  }

  .bar_menu {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    margin: 11px 18px;
    width: 100%;
  }

  .headerDiv_menuBtn {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobile_menu_number_holder{
    display: flex;
    align-items: center;
    width: 40%;
  }
  .hederDiv_callBtn {
    /* width: 100%;
  height: 32px; */
    background: rgba(248, 218, 204, 0.85);
    border-radius: 20px;
    font-family: 'poppins';
    font-weight: 600;
    font-size: 2.3vw;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: #362F2D;
    border: none;
    padding: 5px 5px 5px 5px;
  }
  .mobile_right_button_holder{
    display: flex;
    width: 40%;
    justify-content: center;
  }
  .hederDiv_callBtn_right{
    background: rgba(248, 218, 204, 0.85);
    border-radius: 20px;
    font-family: 'poppins';
    font-weight: 600;
    font-size: 2.3vw;
    letter-spacing: 0em;
    text-transform: capitalize;
    color: #362F2D;
    border: none;
    padding: 5px 5px 5px 5px;
  }
  .download_button {
    background: rgba(248, 218, 204, 0.85);
    border-radius: 28px;
    font-family: 'poppins';
    font-weight: 600;
    font-size: 13px;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: #362F2D;
    border: none;
    padding: 5px 10px 5px 17px;
    display: flex;
    align-items: center;
  }

  .submit_button {
    background-color: #657FE3;
    border-radius: 28px;
    font-family: 'poppins';
    font-weight: 600;
    font-size: 3.2vw;
    text-transform: capitalize;
    color: #FFFFFF;
    border: none;
    padding: 5px 10px 5px 5px;
    margin-top: 3%;
    display: flex;
    align-items: center;
  }

  .know_more_button {
    border-radius: 28px;
    font-family: 'poppins';
    font-weight: 600;
    font-size: 3vw;
    text-transform: capitalize;
    color: #FFFFFF;
    border: none;
    padding: 5px 10px 5px 5px;
    display: flex;
    align-items: center;
    
  }

  .clGreen {
    background-color: #51BCA9;
  }

  .clTransparent {
    background: rgba(255, 255, 255, 0.2);
  }

  .clTransparentGreen {
    background: rgba(101, 105, 67, 0.2);
  }

  .clTransparentBrown {
    background: rgba(140, 124, 96, 0.2);
  }

  .mr20 {
    margin-right: 20px;
  }
  .mobile_logo_holder{
    z-index: 999;
    margin-top: 16%;
  }
  .ashburyLogo {
    
    z-index: 999;
    /* border: 1px solid; */
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 50%;
    /* padding: 0 0 0 6%; */
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
    /* margin-top:2%; */
  }
  .logo_width{
  width:60px;
}
  /* .download_button {
    background: rgba(248, 218, 204, 0.85);
    border-radius: 28px;
    font-family: 'poppins';
    font-weight: 600;
    font-size: 13px;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: #362F2D;
    border: none;
    padding: 5px 10px 5px 5px;
  } */

  .clGreen {
    background-color: #51BCA9;
  }

  .clTransparent {
    background: rgba(255, 255, 255, 0.2);
  }

  .clTransparentGreen {
    background: rgba(101, 105, 67, 0.2);
  }

  .clTransparentBrown {
    background: rgba(140, 124, 96, 0.2);
  }

  .mr20 {
    margin-right: 20px;
  }



  .homeMainDiv {
    /* background-color: #F8F6FF; */
    background-color: #FFFFFF
  }

  .frm_cntrl_icon {
    position: absolute;
    top: 58%;
    left: 92%;
  }
  .w33{
    width:100%
  }
  .w70{
    width: 100%;
  }
  .field_holder_frm {
    display: block;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .slider_mobile{
    display: block;
  }
  .slider_web{
    display: none;
  }
  .homeMainDiv_Text {
    text-align: center;
    color: #36302B;
    background-color: #F2EBE9;
    font-family: 'poppins';
    font-style: normal;
    padding-top: 13%;
    padding-bottom: 15%;
    display: block;
  }

  .homeMainDiv_Text_Ashbury {
    font-weight: 400;
    font-size: 13px;
    line-height: 109.9%;
    letter-spacing: 0.1em;
    height: 45px;
  }

  .homeMainDiv_Text_BuildStandard {
    font-weight: 600;
    font-size: 32px;
    letter-spacing: -0.02em;
  }

  .standard {
    margin-top: -5%;
  }

  .homeMainDiv_Image {
    /* background-image: url('../Assets/Images/bgimage.png'); */
    /* align-items: center; */
    height: 320px;
    background-repeat: no-repeat;
    border-radius: 40px 40px 0 0;
    margin-top: 11%;
    width: 100%;
    /* display: flex; */
  }

  .img_slider_style {
    border-radius: 40px 40px 0 0;
    width: 100%
  }

  .homeMainDiv_Image_Text {
    text-align: right;
    width: 72px;
    height: 18px;
    /* background-color: pink; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 9px;
    padding-right: 2%;
    margin-left: 75%;
    padding-top: 5%;
  }

  .homeMainDiv_Image_Button {
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-top: 10%;
    justify-content: space-between;
    margin: -17% 0% 15% 30%;
    padding-right: 3%;

  }

  .homeMainDiv_Image_Button_Quote {
    background-color: #657FE3;
    border-radius: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    border: none;
    padding: 5px 5px 5px 15px;
    margin-bottom:0%;
 
  }

  .whatsapp_button {
    background-color: #1EB07C;
    border: none;
    height: 55px;
    width: 55px;
    border-radius: 50px;
    align-items: center;
    padding: 5px;
    box-shadow: 0px 15px 7px -5px rgba(0, 0, 0, 0.4);

  }
  .wa_icon_size{
    width:72%;
  }
  .facebook_button {
    background-color: #347bff;
    border: none;
    height: 40px;
    width: 40px;
    border-radius: 40px;
    align-items: center;
    padding: 5px;
  }

  .homeMainDiv_Image_Button_Chatbtn_Image {
    padding-left: 5%;
  }

  .homeMainDiv_Products {
    background-color: #C9E7ED;
    /* height: 755px; */
    border-radius: 40px 40px 0 0;
    margin-top: 0%;
    font-family: 'Poppins';
    font-style: normal;
    color: #262F1D;
    text-align: center;
  }

  .card_holder {
    display: flex;
    width: 100%;
    flex-direction: row;
    /* justify-content: center; */
    margin: 0% 0%;
    flex-wrap: wrap;
    /* padding-right: 5%; */
    padding-bottom: 10%;

  }

  .product-card_holder {
    display: flex;
    width: 100%;
    flex-direction: row;
    /* justify-content: space-between; */
    margin: 1% 1%;
    flex-wrap: wrap;
    /* background-color: #1EB07C; */
    margin-left: 5%;
    margin-right: 5%;
  }
  .alice-carousel__next-btn{
    width: 15%!important;
    border: 1px solid #F15864;
    background: rgba(248, 218, 204, 0.5);
    border-radius: 10px;
    /* opacity: 0.5; */
    position: absolute;
    top: 40%;
    right: 0%;
}
.alice-carousel__prev-btn{
  width: 15%!important;
  border: 1px solid #F15864;
  background: rgba(248, 218, 204, 0.5);
  border-radius: 10px;
  /* opacity: 0.5; */
  position: absolute;
  top: 40%;
  left: 0%;
}
.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item{
  color: #F15864!important;
  font-size: 24px;
  font-weight: bold;
}
.alice-carousel__prev-btn-wrapper{
  text-align: center;
}
  .card_view {
    background-color: #FFFFFF;
    /* width:30%; */
    width: 94%;
    border-radius: 25px;
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    padding: 10% 7%;
    /* margin:8% 8% */
    margin-left: 0%;
    margin-top: 3%;
  }

  .homeMainDiv_Products_header {
    /* background-color: yellow; */
    font-weight: 600;
    font-size: 24px;
    line-height: 72px;
    letter-spacing: -0.02em;
    margin-bottom: -5%;
  }

  .homeMainDiv_Products_headersub {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.01em;
    /* margin-top:2%; */

  }



  .iko_div_text {
    margin-bottom: 0%;
    text-align: left;
    min-height: 150px;
  }

  .homeMainDiv_products_card_header {
    font-weight: 600;
    font-size: 13px;
    line-height: 118.4%;
    color: #362F2D;
    text-transform: capitalize;
  }

  .homeMainDiv_products_card_text {
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: #5E5957;
    margin-top: 10%
  }

  .iko_div {
    margin-bottom: 15%;
    min-height: 70px;
  }

  .homeMainDiv_Image_Button_dowmloadbtn {
    background-color: rgba(241, 88, 100, 0.19);
    border-radius: 28px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #362F2D;

  }


  .product_cards {
    /* background-color: pink; */
    /* padding:20px 10px 20px 10px  */
    /* padding-right:5%; */
    /* padding-left:5%  */
    /* margin-top:50% */
    padding: 7%
  }

  .form_quote {
    background: #eff0f6;
    border-radius: 40px;
    height: 727px;
    /* margin-top: -10%; */
    text-align: center;
    padding-top: 5%;
    margin-top:5%;
    /* padding-bottom: 15%; */
  }

  .form_header {
    font-weight: 600;
    font-size: 24px;
    line-height: 72px;
    letter-spacing: -0.02em;
    color: #362F2D;
    font-family: 'Poppins';
    font-style: normal;
  }

  .form_text_class {
    margin-top: -15px;
    font-size: 15px;
    line-height: 24px;
  }

  .form_text {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: rgba(54, 47, 45, 0.76);
    font-family: 'Roboto';
    font-style: italic;
  }

  .form_field_class {
    /* background-color: pink; */
    padding: 8%;
    text-align: left;
  }

  .submit_btn {
    /* display:flex; */
    /* justify-content: 'center'; */
    align-items: center;
    /* background-color: #FFFFFF; */
    padding-left: 34%;
    background-color: #657FE3;
    border-radius: 28px;
  }

  .Asbury_main {
    background-color: #F2E4E0;
    border-radius: 40px;
  }

  .Ashbury_international {
    /* height: 1039px; */
    background-color: #FFFFFF;
    border-radius: 40px;
    border-color: #FFFFFF;
    display: block;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0% 0%;

  }

  .group_div {
    margin-top: 10%;
    /* background-color: pink; */
    display: flex;
    justify-content: center;

  }
  .about_w50{
    width:100%
  }
  .about_w35{
    width: 36%;
  }
  .about_ml_35{
    margin-left: 35%;
  }
  .about_mb_10{
    margin-bottom: 10%;
  }
  .text {
    margin-top: 8%;
    text-align: center;
    margin-left: 0%;
  }
  .aboutus_img{
  
  }
  .Ashbury_international_title {
    /* background-color: pink; */
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #262F1D;
    /* width:260px;
height:52px; */
    margin-left: 20%;
    margin-right: 20%;
    /* padding-left:10%;
padding-right:10%; */
    /* width:10% */
  }

  .Ashbury_international_subtitle {
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #3B472F;
    margin-top: 3%;

  }

  .Ashbury_international_txt_1 {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #5E5957;
    margin-top: 7%;
    margin-left: 10%;
    margin-right: 10%
  }

  .Ashbury_international_txt_2 {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #5E5957;
    margin-top: 5%;
    margin-left: 10%;
    margin-right: 10%
  }

  .Ashbury_international_txt_footer {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #0F101C;
    margin-top: 5%;
    margin-left: 10%;
    margin-right: 10%
  }

  .News_div {
    /* height:985px; */
    background-color: #F2E4E0;
    text-align: center;

  }



  .OurBlog_blue {
    background-color: #F8F6FF;
    border-color: #F8F6FF;
    border-radius: 40px 40px 0 0;
    padding: 0% 0%;
    display: block;
  }


  .OurBlog_white {
    background-color: red;
    border: 40px;
  }

  .working_div {
    background-image: url("../Assets/Images/working.png");
    /* background-color:#FFFFFF; */
    margin-top: 10%;
    border-radius: 40px 40px 0 0;
    background-repeat: no-repeat;
    height: 290px;
    width:100%;
  }

  .shareicon_div {
    display: flex;
    justify-content: flex-end;
    padding-top: 5%;
    margin-right: 5%;

  }

  .working_div_text {
    margin-top: 7%;
    width: 70%;
    text-align: left;
    padding-left: 5%;

  }

  .button_holder_knowmore_1 {
    padding-top: 10%;
    padding-bottom: 20%
  }

  .working_div_text1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    /* margin-left:20%;
  margin-right:20%; */

  }

  .working_div_text2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: 3%;
  }

  .Benifits {
    background-color: #E9EDC9;
    border-radius: 40px;
    margin-top: -10%;
    padding-top: 2%;
    text-align: left;
    height: 280px;
    width: 100%;
  }

  .Benifits_shareicon_div {
    margin-left: 85%;
    padding-top: 5%;

  }


  .Benifits_text_div {
    /* background-color: yellow; */
    /* margin-top: -7.3%; */
    margin-left: 5%;
    margin-right: 30%
  }

  .Benifits_text_1 {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #494B34;

  }

  .Benifits_text_2 {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #494B34;
    margin-top: 3%;
  }

  .Decra_metal {
    background-color: #E3D3B7;
    margin-top: -12%;
    border-radius: 40px;
    text-align: left;
    height: 220px;
    width:100%
  }



  .support {
    text-align: center;
    margin-top: -7%;
    /* background-color: #F8F6FF; */
    background-color: #F8F6FF;
    padding-top: 10%;
    border-radius: 0 0 40px 40px;
    padding-bottom: 5%;
  }


  .Support_header {
    font-weight: 600;
    font-size: 28px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #262F1D;
  }

  .Support_headersub {
    margin-left: 30%;
    margin-right: 30%;
    margin-top: 2%;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #3B472F;
  }

  .reason {
    text-align: center;
    margin-top: -7%;
    /* background-color: #F8F6FF; */
    background-color: #FFFFFF;
    padding-top: 10%;
    padding-bottom: 0%;
    border-radius: 0 0 40px 40px;
  }


  .reason_header {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #262F1D;
    margin-left: 20%;
    margin-right: 20%;
  }

  .reason_headersub {
    margin-top: 2%;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #3B472F;
  }

  .reason_card_1 {
    width: 94%;
    border-radius: 25px;
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    /* justify-content: space-between; */
    height: 230px;
    padding-top: 25%;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-left: 0%;
    margin-top: 3%;

    /* margin-bottom: 20%; */

  }

  .bk_img_1 {
    background-image: linear-gradient(180deg, rgba(38, 53, 112, 0) 50%, #1F368D 100%), url('../Assets/Images/certified.png');
    background-repeat: no-repeat;
    background-size: cover;
    
  }

  .bk_img_2 {
    background-image: linear-gradient(180deg, rgba(38, 53, 112, 0) 50%, #1F368D 100%), url('../Assets/Images/Best_price.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bk_img_3 {
    background-image: linear-gradient(180deg, rgba(38, 53, 112, 0) 50%, #1F368D 100%), url('../Assets/Images/We_think.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bk_img_4 {
    background-image: linear-gradient(180deg, rgba(38, 53, 112, 0) 50%, #1F368D 100%), url('../Assets/Images/Personalized.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bk_img_5 {
    background-image: linear-gradient(180deg, rgba(38, 53, 112, 0) 50%, #1F368D 100%), url('../Assets/Images/Experience.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bk_img_6 {
    background-image: linear-gradient(180deg, rgba(38, 53, 112, 0) 50%, #1F368D 100%), url('../Assets/Images/think.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bk_img_portfolio {
    background-image: url('../Assets/Images/project_back.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .portfolio_image_holder {
    width: 100%;
    height: 550px;
    position: relative;
    margin-top: 20%;
    padding-bottom: 0%;
    padding-left: 7%;
  }
  .portfolio_holder_mobile{
    display: block;
  }
  .portfolio_holder_web{
    display: none;
  }
  .portfolio_show_holder_web{
    display: none;
  }
  .location_map_width{
    width: 100%;
    border-radius:20px
  }
  .hexagon {
    display: flex;
    width: 33%;
    height: 155px;
    background-color: #424242;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    position: absolute;
    left: 42px;
    top: -27px;

  }

  .hexagon2 {
    display: flex;
    width: 33%;
    height: 155px;
    background-color: #424242;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    position: absolute;
    left: 133px;
    top: 117px;

  }

  .hexagon3 {
    display: flex;
    width: 33%;
    height: 155px;
    background-color: #424242;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    position: absolute;
    left: 222px;
    top: 262px;

  }

  .reason_content {
    /* background-color: pink; */
    margin-top: 15%;
    padding-top: 0%;
      /* margin-bottom: 30%; */
  }

  .reason_content_txt {
    margin-top: 3%;
    margin-left: 8%;
    margin-right: 8%;
    /* border: 1px solid;   */


  }

  .Portfolio {
    background-color: #F8F7FF;
    border-radius: 40px;
    text-align: center;

  }

  .Portfolio_header {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 24px;
    line-height: 72px;
    letter-spacing: -0.02em;
    color: #362F2D;
  }

  .Portfolio_text {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: rgba(54, 47, 45, 0.76);
    padding-left: 17%;
    padding-right: 17%;
  }

  /* For hexagon div */

  .hexagon1 span {
    position: absolute;
    display: block;
    float: left;
    border-left: 170px solid #fff;
    border-right: 170px solid #fff;
  }

  .top1 {
    top: 0;
    border-top: 0px solid transparent;
    border-bottom: 75px solid transparent;
  }

  .bottom1 {
    bottom: 0px;
    border-bottom: 0px solid transparent;
    border-top: 75px solid transparent;
  }

  .hexagon1 {
    background: url("../Assets/Images/prj_01_bi.png");
    /* background-repeat: ; */
    width: 340px;
    height: 295px;
    position: relative;
    display: inline-block;
  }






  .clients {
    text-align: center;
    margin-top: 10%;
    padding-top: 0%;
  }

  .clients_header {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #262F1D;
  }

  .clients_text {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #3B472F;
  }

  .clients_images {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 7%;
    padding-left: 5%;
    padding-right: 0%;
    /* background-color: pink; */

  }



  .office {
    text-align: center;
    margin-top:0%;
    background-color: #FFFFFF;
  }

  .office_header {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 24px;
    line-height: 72px;
    color: #362F2D;
    text-align: center;
  }

  .office_text {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: rgba(54, 47, 45, 0.76);
    margin-left: 15%;
    margin-right: 15%;
    text-align: center;
  }

  .card_list {
    margin-top: 8%;
    padding-left: 5%;
    padding-right: 5%;
    display: block;
  }



  .Office_card {
    align-items: left;
    border: 1px solid rgba(190, 189, 201, 0.73);
    border-radius: 30px;
    background-color: #FFFFFF;
    margin-top: 3%;
    text-align: left;
    padding-left: 8%;
    width: 100%;
    padding-bottom: 0%;
    height: auto;
    /* margin-left:5%; */

  }

  .Office_card_header {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #453F33;


  }

  .Office_card_body {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #4F4635;
    margin-top: 1%;
    margin-right: 0%;
  }


  .Contact {
    text-align: center;
    /* margin-top: -10%; */
    /* background-color: #F8F6FF; */
    background-color: #F2EBE9;
    padding-top: 1%;
    border-radius: 40px;
    margin-top: 10%;
    padding-bottom: 0%;
    /* padding-bottom: %; */
  }

  .Support_headersub_ccontact {
    margin-left: 22%;
    margin-right: 22%;
    margin-top: 2%;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #3B472F;
  }

  .Office_card_body_final {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #4F4635;
    margin-top: 5%;
    margin-right: 15%;
  }



  .Footer_text {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    color: #5E5957;
    margin-left: 9%;
    margin-right: 9%;
    margin-top:4%;
  
  }





  .Email {
    background-color: #F8F6FF;
    border-radius: 40px;
    margin-top: 15%;
    /* margin-right:10%; */


  }

  .email_btn_space {
    margin-top: -5%;
  }

  .email_button {
    background-color: rgba(101, 127, 227, 0.19) !important;
    /* width:290px; */
    /* margin-top: 5%; */
  }

  .Navigation {
    display: flex;
    /* flex-direction: row; */
    justify-content: space-between;
    margin-top: 5%;
    margin-left: 15%;
    margin-right: 15%;

    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #211F2A;
  }


  .Navigation_Gallery_blog {
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    margin-top: 5%;
    margin-left: 15%;
    margin-right: 15%;

    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #211F2A;
  }


  .Gallery {
    margin-right: 10%;

  }

  .need_help_button_holder {
    background-color: #F15864;
    padding: 7px 10px;
    border-radius: 28px;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* width: 65%; */
    /* justify-content: space-between; */
    font-family: 'Roboto';
    font-weight: 600;
    margin-bottom: 0%;
  }

  .call_btn {
    background-color: #FFFFFF;
    color: #F15864;
    padding: 5px 7px;
    border-radius: 28px;
    border: none;
    font-family: 'Roboto';
    font-weight: 600;
    margin-right: 18px;
  }

  .copyrightDiv {
    width: 100%;
    background-color: #1F242C;
    display: flex;
    height: 50px;
    flex-direction: row;
    color: #FFFFFF;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    align-items: center;
  }

  /* AoutUspage css */

  .italic {
    font-style: italic;
  }

  .aboutUsMainDiv {
    background-color: #FFFFFF;
    margin-top: 15%;
  }



  .aboutUs_Asbury_main {
    border-radius: 0 0 40px 40px;
  }

  .aboutUs_pinkdiv {
    background: #F2EBE9;
    border-radius: 40px;
  }

  .aboutUs_bluediv {
    background-color: #F8F7FF;
    border-radius: 40px;
  }

  .Ashbury_international_subtitle_2 {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #0F101C;
    margin-top: 12%;

  }

  .founding {
    background-color: #F2E4E0;
    border-radius: 40px;
  }

  .founding_title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: #523E38;
    margin-top: 15%;
    padding-top: 15%;
  }

  .founding_txt_1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #342521;
    margin-top: 8%;
  }

  .founding_txt_2 {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #523E38;
    margin-top: 2%;
  }

  .dash {
    border: 0.5px solid #AD9B96;
    width: 70%;
    height: 0px;
    margin-left: 15%;
    margin-right: 15%;
  }

  .dash-15 {
    margin-top: 15%;
    margin-bottom: 15%;
  }

  .branch {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: capitalize;

    color: #262F1D;
  }

  .branch_sub_txt {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    text-align: center;
    letter-spacing: -0.02em;
    text-transform: capitalize;

    color: #262F1D;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 3%;
    padding-bottom: 10%;
  }

  .ourVision {

    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    /* identical to box height, or 108% */

    text-align: center;
    letter-spacing: -0.02em;
    text-transform: capitalize;

    color: #262F1D;
    margin-top: 12%;
  }

  .ourVision_sub_1 {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    text-align: center;
    letter-spacing: -0.02em;

    color: #3B472F;
    margin-top: 3%;

  }

  .ourVision_sub_2 {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    /* or 154% */

    text-align: center;
    letter-spacing: -0.02em;

    color: #3B472F;
    margin-top: 8%;
    margin-left: 15%;
    margin-right: 15%;
    padding-bottom: 18%;
  }

  .vision_card {
    background-color: #EEECF5;
    border-radius: 30px;
    margin-left: 7%;
    margin-right: 7%;
    padding-bottom: 10%;
  }

  .vision_card_header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height, or 150% */

    text-align: center;
    text-transform: capitalize;

    color: #494B34;
    padding-top: 10%;
    margin-bottom: 8%;
  }

  .vision_card_txt_1 {

    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    text-align: center;

    color: #494B34;
    /* padding-bottom: 10%; */
    margin-top: 4%;
    margin-left: 12%;
    margin-right: 12%;
  }

  .dash-5 {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .dash_1 {
    border: 2px solid #8378A5;
    width: 20%;
    height: 0px;
    margin-left: 40%;
    margin-right: 40%;
    margin-top: -5.5%;
  }

  .copyrightDiv {
    width: 100%;
    background-color: #1F242C;
    display: flex;
    height: 50px;
    flex-direction: row;
    color: #FFFFFF;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    align-items: center;
  }

  /*DROPDOWN BUTTON*/
  .dropdown-content {
    position: absolute;
    background: rgba(34, 31, 44, 0.92);
    border-radius: 0px 0px 30px 30px;
    width: 100%;
    min-height: 429px;
    text-align: center;
    color: #fff;
    top: 46px;
    z-index: 99;
    /* transition: all .5s ease-in-out; */
  }

  .menuHolder {
    margin-top: 10%;
  }

  .menuHolder {
    margin-top: 10%;
  }

  .menuLink {
    padding-top: 5%;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
    /* border: 1px solid #fff; */
    padding-left: 2%;
  }

  .alice-carousel__dots {
    /* margin: -30px 3px 5px !important; */
  }

  /* .menuLink:hover{
  background-color: #657FE3;
  
 
} */




  /* ---------------   BlogPage ----------------------  */


  .BlogMainDiv {
    background-color: #FFFFFF;
    padding-left: 5%;
    padding-right: 5%;
  }

  .BlogMainDiv_News_title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 72px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #262F1D;
    margin-top: 10%;
  }

  .BlogMainDiv_News_subtitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #3B472F;
    padding-Left: 20%;
    padding-right: 20%;
  }

  .Blogcard {
    background-color: #F8F6FF;
    border-radius: 40px;
    margin-top: 8%;
    padding-bottom: 10%;
    width: 100%;
    margin-left: 0%;
  }

  .Blog_image {
    width: 100%;
  }

  /* .Blog_image{
  border-radius: 40px;

} */

  .Blog_image_property {
    border-radius: 40px;
    width: 100%;
    height: 300px;

  }


  .Blogtextdiv {
    margin-top: 7%;
    padding-left: 5%;
    padding-right: 5%;
    /* background-color: #1EB07C; */
  }

  .Blogcardtext_1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    color: #262F1D;
    margin-top: 5%;
  }

  .Blogcardtext_2 {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-transform: capitalize;
    color: #7286D3;
    margin-top: 3%;
  }


  .Blogcardtext_3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #5E5957;
    margin-top: 3%;
    /* padding-left:5%; */
    /* padding-right: 5%; */
  }


  .Blogcardtext_4 {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-transform: capitalize;
    color: #70747E;
  }



  .mt_6 {
    margin-top: 6%;
  }

  .blog_share_icon {
    position: absolute;
    left: 88%;
    top: 10px;
  }

  /*------------------  Blog Details Page--------------------- */

  .italic_class {
    font-style: italic;
  }

  .fw {
    font-weight: 400 !important;
  }

  /* .ff{
    font-family: 'Roboto' !important;
   } */

  .List_class {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #5E5957;
  }

  .Blogcardtext_title {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    color: #262F1D;
  }


  .blog_detail-form_quote {
    background: #FFFFFF;
    border-radius: 40px;
    height: 727px;
    /* margin-top: -10%; */
    text-align: center;
  }


  .blogdetails-form_header {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;


    text-align: center;

    color: rgba(54, 47, 45, 0.76)
  }

  .Blog-detail-social {
    display: flex;
    flex-direction: row;
    margin-top: 3%;
  }


  .ml_40 {
    margin-left: 40%;
  }

  /* gallary page styles */

  .gallary_pinkdiv {
    background-color: #F2EBE9;
    text-align: center;
    /* padding-top: 5%; */
    margin-top: 15%;
    border-radius: 0 0 40px 40px;
  }

  .gallary_whitediv {
    background-color: #FFFFFF;
    border-radius: 0 0 40px 40px;
    padding-bottom: 20%;
  }


  .m_r_l {
    margin-left: 10% !important;
    margin-right: 10% !important;
  }
  .gallery_card_holder{
    display: flex;
    flex-direction: column;
    justify-content: normal;
    flex-wrap: wrap;
    padding: 4%;
}
  .gallary_card {
    background: #F8F6FF;
    border-radius: 40px;
    width: auto;
    margin-left: 4%;
    margin-right: 4%;
    margin-top: 5%;
    /* margin-bottom: 5%; */
    /* padding-top: 5%; */
    padding-bottom: 5%;
    margin-bottom: 0%;
    
  }

  .gallary_card_image {
    border-radius: 40px;
  }

  .gallary_image {
    border-radius: 40px;
    width: 100%;
    height: 300px
  }

  .gallary_card_text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height, or 100% */

    text-transform: capitalize;

    color: #262F1D;
    /* padding-top: 4%; */
    /* padding-bottom: 4%; */
    margin-top: 7%;
    margin-bottom: 5%;
    text-align: left;
    margin-left: 10%;

  }


  /* contact us styles */

  .contact_pb {
    padding-bottom: 5% !important;
  }

  .mt_neg {
    margin-top: -5% !important;
  }


  /* ------------------------   Product Page  -------------------------------- */


  .product-text-div {
    text-align: center;
    margin-top: 13%;
    margin-left: 15%;
    margin-right: 15%;
    margin-bottom: 20%;
  }

  .product-text {
    font-family: 'Roboto';
    /* font-weight: 400; */
    font-size: 13px;
    line-height: 18px;
    color: #5E5957;
  }

  .fw400 {
    font-weight: 400;
  }

  .fw500 {
    font-weight: 500;
  }

  .product_text_style {
    font-style: italic;
    /* font-weight: bold; */
  }

  .product-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #262F1D;
  }

  .text-color {
    color: #212034
  }



  .product-subtitle {
    font-family: 'Roboto';
    font-style: italic;
    /* font-weight: 500; */
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #5E5957;
  }

  .product-reason_content_txt {
    margin-top: 45%;
    margin-left: 10%;
    margin-right: 10%;
  }


  .product-blue {
    border-radius: 0 0 40px 40px;
    padding-bottom: 10%;
    background-color: #F8F6FF;
  }


  .product-white {
    border-radius: 0 0 40px 40px;
    background-color: #FFFFFF;

  }

  .footerLink {
    color: #000 !important;
    text-decoration: none !important;
  }

  .subMenuHolder {
    width: 50%;
    margin-left: 45%;
    /* margin-left: 25%; */
    text-align: left;
  }

  .submenulink {
    font-size: 14px;
    padding-top: 5%;
  }

  .footer_email {
    display: flex;
    align-items: center;
    flex-direction: row;
    border-radius: 28px;
    padding: 2%;
    background-color: rgba(101, 127, 227, 0.19);
    border: none;
    font-size: 4vw;
    width:auto;
  }
}